import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'email',
    'invalidFeedbackEmail',
    'submitFormGift',
    'showConfirmEmail',
  ]

  connect() {
    this.element.addEventListener('keydown', this.handleEnterKeyDown.bind(this));
    this.element.addEventListener('keyup', this.handleEnterKeyUp.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.handleEnterKeyDown.bind(this));
    this.element.removeEventListener('keyup', this.handleEnterKeyUp.bind(this));
  }

  handleEnterKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleEnterKeyUp(event) {
    if (event.keyCode === 13) {
      this.createGift(event);
    }
  }

  createGift(event) {
    let $btnSubmit = $(event.target).closest('.button__gift__page').find('button')
    let mailText = this.emailTarget.value
    let invalidEmailTarget = this.invalidFeedbackEmailTarget
    $btnSubmit.prop('disabled', true)
    let disabledButton = false
    let invalidEmail = false

    if (mailText.length > 0) {
      invalidEmailTarget.style['display'] = 'none'
      invalidEmailTarget.textContent = ''
    } else {
      invalidEmailTarget.style['display'] = 'block'
      invalidEmailTarget.textContent = 'メールアドレスを正しく入力してください'
    }

    let self = this
    if (event.keyCode === 13 || event.type === 'click') {
      event.preventDefault();
      Promise.resolve(this.validateEmail(mailText)).then(function(response){
        if(!response) {
          invalidEmail = true
          disabledButton = true
          self.emailTarget.style['border'] = '1px solid #C31731'
        } else {
          self.showConfirmEmail();
          event.preventDefault();
        }

        $btnSubmit.prop('disabled', disabledButton);
      })
    }
  }

  validateEmail(email) {
    let invalid = this.invalidFeedbackEmailTarget

    let validEmail = email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (validEmail == null) {
      invalid.style['display'] = 'block'
      invalid.textContent = 'メールアドレスを正しく入力してください'
      return false
    }

    invalid.style['display'] = 'none'
    invalid.textContent = ''
    return true
  }

  changeFocus() {
    this.submitFormGiftTarget.removeAttribute('disabled')
    let invalidEmailTarget = this.invalidFeedbackEmailTarget
    invalidEmailTarget.style['display'] = 'none'
    invalidEmailTarget.textContent = ''
  }

  showConfirmEmail() {
    $('.header-title__title h2').text('入力内容確認')
    $('.form-group.confirm').removeClass('d-none')
    $('.button__gift__page.page-confirm').removeClass('d-none')
    $('.form-group.edit').addClass('d-none')
    $('.button__gift__page.edit').addClass('d-none')

    const emailValue = $('.col-sm-12.edit .email-input').val()
    $('.col-sm-12.confirm .email-input').removeClass('d-none')
    $('.col-sm-12.edit .email-input').addClass('d-none')
    $('.col-sm-12.confirm .email-input').val(emailValue)
    
  }
}
